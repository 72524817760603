export const titleDict = {
    'lotoclub': 'index.lotoClub',
    'slots': 'index.slots',
    'roulette': 'index.roulette',
    'minigames': 'index.minigames',
}

export const providersIcons = {
    Betsoft: require("../../../assets/icons/index/providers/Betsoft.png"),
    Endorphina: require("../../../assets/icons/index/providers/Endrophina.png"),
    Evolution: require("../../../assets/icons/index/providers/Evolution.png"),
    Igrosoft: require("../../../assets/icons/index/providers/Igrosoft.png"),
    Platipus: require("../../../assets/icons/index/providers/Platipus.png"),
    Playsoft: require("../../../assets/icons/index/providers/Playsoft.png"),
    Vivo: require("../../../assets/icons/index/providers/Vivo.png"),
    Xprogaming: require("../../../assets/icons/index/providers/Xprogaming.png"),
    CasinoTechnology: require("../../../assets/icons/index/providers/ct.png"),
}

export const providerTitle = key => ({
    easia: 'Lotoclub',
    gaminator: 'Sport CLUB'
}[key] || key);

export const hideCoins = title =>({
    'SPORT BET MOBILE': true
}[title] || false)


export const gamesImages = {
    'Chance Machine 40': 'assets/games/chance_machine_20.webp',
    'Hit the Gold!': 'assets/games/hit_the_gold.webp',
    'HOT 7\'s X 2': 'assets/games/hot_777_x2.webp',
    'Hot Coins: Hold and Win Mobile': 'assets/games/hot_coins.webp',
    'Lucky Clover': 'assets/games/lucky_clover.webp',
    'Lucky Streak 3': 'assets/games/lucky_streak_3.webp',
    'Solar Queen Mobile': 'assets/games/solar_queen.webp',
    'Sun of Egypt 2': 'assets/games/sun_of_egypt_2.webp',

    'Video Roulette': 'assets/games/roulette.webp',
    // 'RushBingo': require('../../../assets/icons/index/bingo37.svg'),
    'Dongelek': 'assets/games/dongelek.webp',
    'SPORT BET MOBILE': 'assets/games/loto-baner-sport3.webp',
};
