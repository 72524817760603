<template>
  <Popper trigger="clickToToggle" ref="popper" :visible-arrow="false">
    <div class="popper">
      <div class="list">
        <div class="item" @click="select(lang)" v-for="lang in variants" :key="lang">
          <div :class="['image', lang]"></div>
          <div class="label">{{ labels[lang] }}</div>
        </div>
      </div>
    </div>

    <div class="switcher" slot="reference" v-if="!small">
      <div :class="['current', $i18n.locale]"></div>
    </div>

    <div :class="['small-switcher', $i18n.locale]" slot="reference" v-if="small"></div>

  </Popper>
</template>

<script>
import Popper from 'vue-popperjs';

//$i18n.locale
export default {
  name: "LanguageSwitcher",
  props: ['popperOptions', 'onClose', 'small'],
  data() {
    return {
      variants: ['ru', 'az', 'en', 'kg', 'kz', 'uz', 'tr', 'in', 'ua'],
      labels: {
        ru: 'Русский',
        az: 'Azərbaycan dili',
        en: 'English',
        kg: 'Кыргыз тили',
        kz: 'Қазақ тілі',
        uz: 'Оʻzbek tili',
        tr: 'Türk dili',
        in: 'हिन्दी, हिंदी',
        ua: 'Українська мова'
      }
    }
  },
  components: {Popper},
  methods: {
    select(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem('$i18n.locale', lang);
      this.$refs.popper.doClose();
      this.onClose && this.onClose();
    }
  }
}
</script>

<style scoped>
.popper {
  background-color: #0e0f19;
  border: 0;
  box-shadow: none;
  border-radius: 20px;
  margin-right: 16px;
}
.list {
  display: flex;
  gap: 14px;
  flex-direction: column;
  margin: 8px;
}

.item {
  display: flex;
  flex-direction: row;
}

.label {
  flex-grow: 1;
  align-self: center;
  text-align: left;
  margin: 0 8px;
  color: #fff;
}

.image {
  width: 40px;
  height: 40px;
  flex-grow: 0;
  background-size: contain;
}

.small-switcher {
  height: 32px;
  width: 32px;
  background-size: contain;
  background-position: center;
}

.switcher {
  position: relative;
  height: 45px;
  width: 50px;
  background-size: contain;
  background-position: center;
  background-image: url("images/select.webp");
}

.current {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 25px;
  width: 25px;
  background-size: contain;
  background-position: center;
}

.ru {
  background-image: url("images/ru.png");
}

.az {
  background-image: url("images/az.png");
}

.en {
  background-image: url("images/en.png");
}

.kg {
  background-image: url("images/kg.png");
}

.kz {
  background-image: url("images/kz.png");
}

.uz {
  background-image: url("images/uz.png");
}

.tr {
  background-image: url("images/tr.png");
}

.in {
  background-image: url("images/in.png");
}

.ua {
  background-image: url("images/ua.png");
}
</style>
