<template>
  <div :class="['bonus-banner', { closed: !isShow }]" v-if="canShow" @click="onClickHandler">
    <div class="container">
      <div class="bonus-banner-text" v-html="$t('banners.bonusBanner.title')"></div>
      <div class="bonus-banner-btn">
        {{ $t("banners.bonusBanner.action").replace("+20%", `+${this.promoCodeData.percent}%`) }}
      </div>
      <div class="bonus-banner-clock">
        <div class="bonus-banner-clock-time" :class="{ 'with-hours': secondsToTime(timerSeconds).hours > 0 }">
          <template v-if="secondsToTime(timerSeconds).hours > 0">
            <span>{{ secondsToTime(timerSeconds).hours }}</span>
            <span class="time-separator">:</span>
          </template>
          <span>{{ secondsToTime(timerSeconds).minutes }}</span>
          <span class="time-separator">:</span>
          <span :class="{ 'sub-seconds': secondsToTime(timerSeconds).hours > 0 }">{{
            secondsToTime(timerSeconds).seconds
          }}</span>
        </div>
      </div>
      <div class="close" id="bonusBannerClose" @click="onClickHandler">
        <img src="./img/close.webp" @click="onClickHandler" width="14" height="auto" />
      </div>
    </div>
  </div>
</template>

<script>
import { LocalStorage } from "ttl-localstorage";

const storageKey = "BonusBanner.closed";
const autoCloseTimerDefault = 120;

export default {
  name: "BonusBanner",
  data() {
    return {
      timerInterval: null,
      isShow: false,
      timerSeconds: 0,
      autoCloseTimer: autoCloseTimerDefault,
    };
  },

  mounted() {
    // Фоновый таймер отслеживающий надо ли показывать баннер
    setInterval(() => {
      if (this.promoCodeData && !this.isShow && !LocalStorage.get(storageKey)) {
        this.open();
      }
    }, 1000);
  },

  computed: {
    promoCodeData() {
      return this.$store.state.activePromoCode.length ? this.$store.state.activePromoCode[0] : null;
    },
    isApp() {
      return this.$store.state.isAndroidApp;
    },
    isPwa() {
      return navigator.standalone || window.matchMedia("(display-mode: standalone)").matches;
    },
    url() {
      return this.$store.state.config.appUrl;
    },
    canShow() {
      return !this.isApp && !this.isPwa && this.url && this.promoCodeData;
    },
  },

  methods: {
    onClickHandler(e) {
      if (e.target.id !== "bonusBannerClose" && !e.target.closest("#bonusBannerClose")) {
        this.$router.push({ name: "deposit" });
      }

      this.close();
    },
    startTimer() {
      this.timerInterval = setInterval(() => {
        this.timerSeconds--;
        this.autoCloseTimer--;

        if (this.timerSeconds <= 0 || this.autoCloseTimer <= 0) {
          this.close();
        }
      }, 1000);
    },

    secondsToTime(secs) {
      const date = new Date(secs * 1000);
      const hours = date.getUTCHours();
      const minutes = date.getUTCMinutes();
      const seconds = date.getSeconds();

      return {
        hours: hours.toString().padStart(2, "0"),
        minutes: minutes.toString().padStart(2, "0"),
        seconds: seconds.toString().padStart(2, "0"),
      };
    },

    close() {
      clearInterval(this.timerInterval);
      this.isShow = false;
      LocalStorage.put(storageKey, true, 60 * 1.5);
    },

    open() {
      const currentTimestampInSeconds = Math.floor(Date.now() / 1000);
      this.timerSeconds = this.promoCodeData.activeUntil - currentTimestampInSeconds;
      this.autoCloseTimer = autoCloseTimerDefault;

      if (this.timerSeconds > 0) {
        this.isShow = true;
      }

      if (this.isShow) {
        this.startTimer();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.bonus-banner.closed {
  transform: translateY(110%);
}

.bonus-banner {
  transition: all ease 0.7s;
  transform: translateY(0);
  position: fixed;
  bottom: 0;
  height: 150px;
  width: 100%;
  background-color: #fff;
  z-index: 1;

  @media (max-width: 480px) {
    font-size: 21px;
    height: 123px;
  }

  &-text {
    position: absolute;
    top: 10px;
    left: 15px;
    font-size: 27px;
    font-weight: bold;

    @media (max-width: 480px) {
      font-size: 21px;
    }
  }

  &-btn {
    position: absolute;
    bottom: 10px;
    left: 15px;
    background-color: #f95803;
    padding: 10px;
    border-radius: 10px;
    font-size: 23px;
    color: white;
    font-weight: bold;
    box-sizing: border-box;
    @media (max-width: 480px) {
      font-size: 18px;
    }
  }

  &-clock {
    position: absolute;
    bottom: 0;
    right: 35px;
    width: 180px;
    height: 180px;
    background-image: url("./img/clock.webp");
    background-repeat: no-repeat;
    background-position-y: 20px;
    background-size: 100%;

    @media (max-width: 480px) {
      background-size: 80%;
      background-position-y: 0;
      right: -20px;
      height: 130px;
    }

    &-time {
      position: absolute;
      bottom: 18px;
      left: 0;
      width: 181px;
      //text-align: center;
      color: white;
      font-size: 57px;
      letter-spacing: 3px;
      font-family: "Digital", "Gilroy", sans-serif;
      justify-content: center;
      display: flex;
      align-items: baseline;

      @media (max-width: 480px) {
        width: 147px;
        bottom: 14px;
        font-size: 47px;
      }

      .time-separator {
        font-size: 28px;
        font-weight: bold;
        display: flex;
        align-self: center;
      }

      &.with-hours {
        font-size: 55px;

        @media (max-width: 480px) {
          font-size: 43px;
          bottom: 20px;
        }

        .sub-seconds {
          font-size: 25px;

          @media (max-width: 480px) {
            font-size: 24px;
          }
        }
      }
    }
  }
}

.container {
  max-width: 576px;
  display: flex;
  margin: 0 auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  box-sizing: border-box;
  padding: 5px 10px;
  z-index: 1000;
}
</style>
