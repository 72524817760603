<template>
  <div :class="{ disabled: !this.$store.state.loading }" class="preloader">
    <div class="preloader__container">
      <img :src="logo" alt="" />
      <div class="preloader__animation">
        <div class="vectors-group">
          <div class="element">
            <div class="vector"></div>
          </div>
          <div class="element">
            <div class="vector"></div>
          </div>
          <div class="element">
            <div class="vector"></div>
          </div>
          <div class="element">
            <div class="vector"></div>
          </div>
          <div class="element">
            <div class="vector"></div>
          </div>
          <div class="element">
            <div class="vector"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Preloader",
  computed: {
    logo() {
      return this.$store.state.logo;
    }
  }
};
</script>

<style lang="scss" scoped>
.disabled.preloader {
  //left: 100%;
  //right: 100%;
  opacity: 0;
  pointer-events: none;
}
.preloader {
  transition: all 300ms;
  opacity: 1;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #1b1c37;
  z-index: 9999;
  .preloader__container {
    img {
      max-width: 100%;
    }
    .preloader__animation {
      position: absolute;
      bottom: 65px;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      .vectors-group {
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .element {
          width: 100%;
          height: 100%;
          position: absolute;
          justify-content: center;
          display: flex;
          .vector {
            position: absolute;
            width: 3px;
            height: 6px;
            top: 0;
            background: #c4c5e0;
            border-radius: 4px;
            animation: colorChange 1s alternate ease-in-out infinite;
          }
          &:nth-child(1) {
            .vector {
              animation-delay: 0s;
            }
          }
          &:nth-child(2) {
            .vector {
              animation-delay: 0.3s;
              transform: rotate(60deg);
            }
            // transform: rotate(60deg);
          }
          &:nth-child(3) {
            .vector {
              animation-delay: 0.5s;
              transform: rotate(60deg);
            }
            // transform: rotate(120deg);
          }
          &:nth-child(4) {
            .vector {
              animation-delay: 0.7s;
              transform: rotate(180deg);
            }
            // transform: rotate(180deg);
          }
          &:nth-child(5) {
            .vector {
              animation-delay: 0.9s;
              transform: rotate(240deg);
            }
            // transform: rotate(240deg);
          }
          &:nth-child(6) {
            .vector {
              animation-delay: 1.1s;
              transform: rotate(300deg);
            }
            // transform: rotate(300deg);
          }
        }
      }
    }
  }
}
@keyframes colorChange {
  0% {
    background: #c4c5e0;
  }
  100% {
    background: #c4c5e01a;
  }
}
</style>
