<template>
  <div :class="{ 'game-item': true, 'full-width': fullWidth }" @click="showButtons()">
    <div :class="{ buttons: true, buttonsActivated }">
      <div @click="onToggleFavorite(game)" class="favorite" :class="{ 'favorite-active': isFavorite }"></div>
      <div @click="playReal" class="play"></div>
      <div @click="playDemo" class="demo" v-if="game.has_demo"></div>
    </div>
    <div class="coins" v-if="hasCoins()">
      <div class="coin real">$</div>
      <div class="coin bonus" v-if="game.can_play_bonus">B</div>
    </div>
    <div :style="{ backgroundImage: 'url(' + encodeURI(getImage(game)) + ')' }" class="bg"></div>
  </div>
</template>

<script>
import { gamesImages } from "./gamesConfig";
import { hideCoins } from "./gamesConfig";

export default {
  name: "GameItem",
  props: ["game", "onClick", "fullWidth", "favoriteGames", "onToggleFavorite"],
  data() {
    return {
      buttonsActivated: false,
    };
  },
  mounted() {},
  methods: {
    hasCoins() {
      return !hideCoins(this.game.title);
    },

    storeLastGames() {
      let lastGames = localStorage.getItem("lastGames");
      lastGames = lastGames ? JSON.parse(lastGames) : {};

      lastGames[this.game.id] = this.game;

      if (Object.keys(lastGames).length > 4) {
        const firstKey = Object.keys(lastGames)[0];
        delete lastGames[firstKey];
      }

      localStorage.setItem("lastGames", JSON.stringify(lastGames));
    },

    playDemo() {
      localStorage.removeItem("BonusBanner.closed");
      this.onClick();
      this.storeLastGames();
      this.$router.push({ name: "demo", params: { id: this.game.id } });
    },
    playReal() {
      localStorage.removeItem("BonusBanner.closed");
      this.onClick();
      this.storeLastGames();
      this.$router.push({ name: "play", params: { id: this.game.id } });
    },
    getImage(game) {
      return gamesImages[game.title] || game.image;
    },
    showButtons() {
      this.buttonsActivated = true;
      setTimeout(() => (this.buttonsActivated = false), 2500);
    },
  },
  computed: {
    auth() {
      return this.$store.state.auth;
    },
    isFavorite() {
      return this.favoriteGames && this.favoriteGames[this.game.id] !== undefined;
    },
  },
};
</script>

<style scoped>
.bg {
  width: 100%;
  height: 100%;
  /*padding-top: calc((100%) * 0.67);*/
  background-size: cover;
  border-radius: 6px;
  pointer-events: none;
  background-position: center;
}

.game-item {
  height: calc((50vw - 10px) * 0.67);
  margin-bottom: 20px;
  width: calc(50% - 10px);
  position: relative;
}

.full-width {
  height: calc((50vw - 10px) * 0.67 * 2);
  width: 100%;
}

.buttons {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease;
  padding-top: 30%;
}

.buttonsActivated {
  opacity: 1;
  pointer-events: auto;
  padding-top: 0;
}

.coins {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.favorite {
  display: block;
  background-image: url("./favorite.webp");
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: center;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;

  &.favorite-active {
    background-image: url("./favorite-fill.webp");
  }
}

.play {
  display: block;
  background-image: url("./play.webp");
  background-size: 17%;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.demo {
  display: block;
  background-image: url("./demo.webp");
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: 50% 60%;
  width: 35%;
  height: 30%;
  position: absolute;
  bottom: 0%;
  right: 0%;
}

.coin {
  position: absolute;
  background-color: #fbe779;
  border-radius: 50%;
  width: 11%;
  height: calc(11% / 0.67);
  box-shadow: -2px 0px 5px black;
  text-align: center;
  bottom: 5%;
  left: 3%;
  line-height: 4.5vw;
  font-size: 3.5vw;
  font-weight: bold;
}

.coin.bonus {
  left: 10%;
}
</style>
