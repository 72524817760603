<template>
  <div class="application">
    <div class="heading">{{ $t('banners.application.title') }}</div>
    <div class="person"></div>
    <a class="button" :href="appUrl">Скачать</a>
  </div>
</template>

<script>
export default {
  computed: {
    appUrl() {
      return this.$store.state.config.appUrl;
    }
  }
};
</script>

<style lang="scss" scoped>
.application {
  width: 100%;
  min-height: 120px;
  border-radius: 6px;
  background-image: url(../../assets/images/banners/application/background.webp);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #d43000;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  padding: 20px 0 20px 15px;

  .person {
    position: absolute;
    left: auto;
    top: auto;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    padding: 0;
    background-image: url(../../assets/images/banners/application/person.webp);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    z-index: 0;
  }

  .button {
    max-width: 120px;
    padding: 15px 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    color: #ffffff;
    background: #2c2b47;
    border-radius: 6px;
    cursor: pointer;
    transition: 0.2s;
    text-align: center;
    z-index: 100;
  }

  .button:hover {
    background: #1a192a;
  }

  .heading {
    max-width: 180px;
    font-weight: 600;
    line-height: 140.7%;
    color: #ffffff;
    margin-bottom: 15px;
    font-size: 15px;
    z-index: 100;
  }

}
</style>
