<template>
  <Details>
    <template v-slot:title>{{$t('promo.wheel.title')}}</template>
    <template v-slot:image><img src="../../../assets/images/banners/jackpot.webp"/></template>
    <template v-slot:body>
      <div v-html="$t('promo.wheel.body')"></div>
    </template>
  </Details>
</template>

<script>
import Details from "../../../components/Promo/Details";

export default {
  name: "FirstDeposit",
  components: {Details},
}
</script>

<style scoped>

</style>
